import axios from 'utils/Api';
import userAxios from 'utils/userAxios';
import axiosNext from 'utils/axios';
import camelize from 'camelize';
import { multiStateGet } from 'appRedux/reducers/utility';
import { fm } from 'utils/string';
import { get } from 'utils/lodash';
import {
  setFilters,
  setSuperAdminFields,
  setAuthFields,
  setSuperAdminErrors,
  setSnackbarError,
  setSnackbarSuccess,
  setSuperAdminAppVersionFields,
  setSuperAdminPermissionsFields,
  setSuperAdminMobileLoginHistoryFields,
} from 'appRedux/actions';
import { getFormattedListData } from 'appRedux/utility';
import {
  getFormattedCompaniesQueryParams,
  getFormattedInvoicesListData,
  formattedErrorsListData,
  getFormattedUsersListData,
  getFormattedPermissionRequestBody,
  getMobileLoginHistoryQueryParams,
} from './selector';
import { getFormattedPaginationFields } from '../../selectors/utility';

export * from './redDay';
export * from './sms';
export * from './customers';
export * from './items';
export * from './invoices';
export * from './databaseBackup';
export * from './errors';
export * from './webAppVersion';
export * from './users';
export * from './storage';
export * from './companySettings';

export const requestCompanies = (name) => async (dispatch, getState) => {
  const { filters } = getState();
  try {
    const query = getFormattedCompaniesQueryParams(multiStateGet(name, filters));
    const response = await axiosNext.get('/api/v1/super_admin/companies', { params: query });
    const { records: companies, ...rest } = getFormattedListData(response.data.data.companies);
    dispatch(setSuperAdminFields({ companies }));
    dispatch(setFilters({ ...rest, name }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.superAdmin.gettingCompanies.error'));
  }
};

export const requestSearchCompanies = (companyName, name) => async (dispatch) => {
  try {
    const query = { search: true, query: companyName };
    const response = await axiosNext.get('/api/v1/super_admin/companies', { params: query });
    const { records: companies } = getFormattedListData(response.data.data.companies);
    const fieldName = name ? 'companies' : 'records';
    dispatch(setSuperAdminFields({ [fieldName]: companies }));
    if (name) {
      dispatch(setFilters({ page: 1, totalRecords: companies.length, pages: 1, name }));
    }
  } catch (err) {
    dispatch(setSnackbarError('snackbar.superAdmin.searchCompanies.error'));
  }
};

export const requestSearchUsers = (email, name) => async (dispatch) => {
  try {
    const query = { search: true, email };
    const response = await axios.get('/api/v1/super_admin/users', { params: query });
    const { records: users } = getFormattedUsersListData(response.data.data);
    dispatch(setSuperAdminFields({ users }));
    if (name) {
      dispatch(setFilters({ page: 1, totalRecords: users.length, pages: 1, name }));
    }
  } catch (err) {
    dispatch(setSnackbarError('snackbar.superAdmin.searchUsers.error'));
  }
};

export const requestInvoicesOLD = (name) => async (dispatch, getState) => {
  const { filters } = getState();
  try {
    const pagination = multiStateGet(name, filters);
    const query = { page: pagination.page, per_page: pagination.perPage };
    const response = await axiosNext.get('/api/v1/super_admin/invoices', { params: query });
    const { records: oldUIinvoices, ...rest } = getFormattedListData(response.data.data.invoices);
    dispatch(setSuperAdminFields({ oldUIinvoices }));
    dispatch(setFilters({ ...rest, name }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.superAdmin.requestInvoice.error'));
  }
};

export const requestMarkPaidInvoiceOLD = (id, callBack) => async (dispatch) => {
  try {
    await axios.put(`/api/v1/super_admin/invoice_paid/${id}`);
    callBack();
  } catch (err) {
    dispatch(setSnackbarError('snackbar.superAdmin.markInvoicePaid.error'));
  }
};

export const requestDeleteInvoiceOLD = (id, callBack) => async (dispatch) => {
  try {
    await axios.delete(`/api/v1/super_admin/invoice_delete/${id}`);
    callBack();
  } catch (err) {
    dispatch(setSnackbarError('snackbar.superAdmin.deleteInvoice.error'));
  }
};

export const requestCreateCompanyInvoicesOLD = () => async (dispatch) => {
  try {
    await axios.get('/api/v1/super_admin/create_invoices');
    dispatch(setSnackbarSuccess('snackbar.superAdmin.creatingInvoices'));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.superAdmin.creatingInvoices.error'));
  }
};

export const requestCompany = (id) => async (dispatch) => {
  try {
    const response = await axiosNext.get(`/api/v1/super_admin/companies/${id}`);
    const company = response.data.data;
    dispatch(setSuperAdminFields({ company }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.superAdmin.gettingCompany.error'));
  }
};

export const requestActivateCompany = (id) => async (dispatch) => {
  try {
    const response = await axiosNext.get(`/api/v1/super_admin/company_activate/${id}`);
    const company = response.data.data;
    dispatch(setSuperAdminFields({ company }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.superAdmin.activateCompany.error'));
  }
};

export const requestDeactivateCompany = (id) => async (dispatch) => {
  try {
    const response = await axiosNext.get(`/api/v1/super_admin/company_deactivate/${id}`);
    const company = response.data.data;
    dispatch(setSuperAdminFields({ company }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.superAdmin.deactivateCompany.error'));
  }
};

export const requestLoginCompany = (id, history) => async (dispatch, getState) => {
  const { superAdmin: { companies: prevCompanies } } = getState();
  try {
    const { data } = await axios.get(`/api/v1/super_admin/company_signin/${id}`);

    if (data.success && !data.errors) {
      if (get('userAgentData.mobile')(navigator)) {
        const companies = prevCompanies.map(
          (company) => (
            company.id === id
              ? { ...company, token: data.data.user.token, role: data.data.user.role }
              : company
          ),
        );
        return dispatch(setSuperAdminFields({ companies }));
      }
      axios.defaults.headers.Authorization = `Bearer ${data.data.user.token}`;
      userAxios.defaults.headers.Authorization = `Bearer ${data.data.user.token}`;
      localStorage.setItem('token', data.data.user.token);
      localStorage.setItem('role', data.data.user.role);
      localStorage.setItem('user_token', data.data.user.token);
      dispatch(setAuthFields({
        role: data.data.user.role,
        token: data.data.user.token,
        userToken: data.data.user.token,
      }));
      history.replace('/');
      window.location.reload();
    } else {
      dispatch(setSnackbarError('snackbar.superAdmin.loginCompany.error'));
      dispatch(setAuthFields({ errors: data.errors }));
    }
  } catch (err) {
    setSnackbarError('snackbar.superAdmin.loginCompany.error');
  }
};

export const requestActivities = () => async (dispatch) => {
  try {
    const { data } = await axios.get('/api/v1/super_admin/dashboard');
    dispatch(setSuperAdminFields({ activitiesList: data.data }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.superAdmin.gettingActivities.error'));
  }
};

export const requestExtendCustomerTrial = (companyId, newDate) => async (dispatch) => {
  try {
    const payload = { id: companyId, trial_end_date: newDate };
    await axios.put('/api/v1/super_admin/update_trial_end_date', payload);

    dispatch(setSnackbarSuccess('snackbar.superAdmin.trialExtended'));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.superAdmin.trialExtend.error'));
  }
};

export const requestUpdateVersion = (data) => async (dispatch, getState) => {
  const { versions: prevVersions = [] } = getState().superAdmin.appVersion;
  try {
    const reqBody = { mobile_app_version: {
      app_type: data.appType,
      app_version: data.appVersion,
      description: data.description,
    } };
    const response = await axios.put(`/api/v3/mobile_app_versions/${data.id}`, reqBody);
    const version = camelize(response.data.data);
    const versions = prevVersions.map((v) => (v.id === data.id ? version : v));
    dispatch(setSuperAdminAppVersionFields({ versions, showAppVersionModal: false }));
  } catch (err) {
    console.log(err); // eslint-disable-line
  }
};

export const requestAppVersions = () => async (dispatch) => {
  try {
    const response = await axios.get('api/v3/mobile_app_versions');
    const versions = camelize(response.data.data.mobile_app_versions);
    dispatch(setSuperAdminAppVersionFields({ versions }));
  } catch (err) {
    console.log(err); // eslint-disable-line
  }
};

export const requestPermissionsRecords = (name) => async (dispatch, getState) => {
  const { filters } = getState();
  try {
    const { page = 1, perPage: per_page = 10 } = multiStateGet(name, filters);
    const response = await axiosNext.get('/api/v3/super_admin/permissions', { params: { page, per_page } });
    const { records, ...restPagination } = getFormattedListData(response.data.data);
    dispatch(setSuperAdminPermissionsFields({ records }));
    dispatch(setFilters({ ...restPagination, name }));
  } catch (err) {
    dispatch(setSnackbarError(fm('snackbar.users.unlock.error')));
  }
};

export const requestAddPermission = (data, name) => async (dispatch) => {
  try {
    const reqBody = getFormattedPermissionRequestBody(data);
    await axios.post('/api/v1/super_admin/permissions', reqBody);
    dispatch(requestPermissionsRecords(name));
    dispatch(setSuperAdminPermissionsFields({ permission: {}, showPermissionModal: false }));
  } catch (err) {
    dispatch(setSnackbarError(fm('snackbar.users.unlock.error')));
  }
};

export const requestMobileLoginHistory = (name) => async (dispatch, getState) => {
  const { filters } = getState();
  try {
    const params = getMobileLoginHistoryQueryParams(multiStateGet(name, filters));
    const response = await axios.get('/api/v3/login_details', { params });
    const { records } = camelize(response.data.data);
    const paginationFields = getFormattedPaginationFields(response.data.data);
    dispatch(setSuperAdminMobileLoginHistoryFields({ records }));
    dispatch(setFilters({ ...paginationFields, name }));
  } catch (err) {
    dispatch(setSnackbarError(fm('snackbar.users.unlock.error')));
  }
};
