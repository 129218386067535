import { pick } from 'utils/lodash';
import camelize from 'camelize';
import { getFormattedPaginationFields, getFilterEmployeeId } from 'appRedux/utility';

export const getFormattedPermissionRequestBody = (data) => ({
  permission: {
    permission_number: Number(data.permissionNumber),
    role: data.role,
    permission_type: data.permissionType,
    description: data.description,
  },
});

export const getFormattedUsersListData = (response) => ({
  ...getFormattedPaginationFields(response),
  records: (response.records || []).map(camelize),
});

export const getFormattedHelpResources = (response) => ({
  ...getFormattedPaginationFields(response),
  records: camelize(response.records),
});

export const getFormatResourceForList = (resource) => {
  const camelized = camelize(resource);
  return { ...camelized, title: camelized.moduleTitle };
};

export const getFormattedHelpResourceReqBody = (resource) => ({
  help_module_id: resource?.module?.id,
  title_sv: resource.titleSv,
  title_en: resource.titleEn,
  redirect_url: resource.redirectUrl,
});

export const getFormattedCompaniesQueryParams = (filters) => ({
  ...pick(['paid', 'page'])(filters),
  on_trial: filters.onTrial,
  trial_expired: filters.trialExpired,
  previous_customer: filters.previousCustomer,
  per_page: filters.perPage,
  query: filters.query,
});

export const getMobileLoginHistoryQueryParams = (filters) => ({
  page: filters.page,
  per_page: filters.perPage,
  start_date: filters.startDate,
  end_date: filters.endDate,
  email: filters.query,
});

export const getSuperAdminSMSReqParams = (filters) => ({
  start_date: filters.startDate,
  end_date: filters.endDate,
  page: filters.page,
  per_page: filters.perPage,
  company_id: filters.companyId || filters.company?.id,
});
